import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../components/SMFAccordion';
import InfoIcon from '../../assets/img/infoIcon.svg';
import MESSAGE_STRINGS, { ACCORDION_STATUS } from '../../constants/en-us';
import AccordionStatus from '../../components/AccordionStatus';
import * as S from './Style';
import Button from '../../components/Button';
import SMFSlider from '../../components/SMFSlider/index.jsx';
import { TEST_IDS } from '../../constants/index.js';

function ScheduleAutoTrigger({
  enableAccordian,
  scheduleAutoTrigger,
  setScheduleAutoTrigger,
  scheduleAutoTriggerstatus,
  setScheduleAutoTriggerStatus,
}) {
  const { AccordionDetails, AccordionSummary } = Accordion;
  const [isFirstSave, setIsFirstSave] = useState(true);

  const handleSaveClick = () => {
    setIsFirstSave(false);
    setScheduleAutoTriggerStatus(ACCORDION_STATUS.COMPLETED);
  };

  const handleSliderChange = (newValue) => {
    setScheduleAutoTrigger(newValue);
    setScheduleAutoTriggerStatus(ACCORDION_STATUS.IN_PROGRESS);
    if (!isFirstSave) {
      setIsFirstSave(true);
    }
  };

  return (
    <Accordion
      defaultExpanded={enableAccordian}
      disabled={!enableAccordian}
      data-testid={TEST_IDS.SCHEDULE_AUTO_TRIGGER_CONTAINER}
    >
      <AccordionSummary>
        <S.FlexBox>
          <S.AccordionSummaryContainer>
            <S.TitleTypography>
              {MESSAGE_STRINGS.SCHEDULE_AUTO_TRIGGER}
            </S.TitleTypography>
            <S.StyledTooltip
              title={MESSAGE_STRINGS.SCHEDULE_AUTO_TRIGGER_TOOLTIP}
              placement="right-end"
            >
              <S.StyleIconButton>
                <InfoIcon height="1rem" width="1rem" />
              </S.StyleIconButton>
            </S.StyledTooltip>
          </S.AccordionSummaryContainer>
          <AccordionStatus status={scheduleAutoTriggerstatus} />
        </S.FlexBox>
      </AccordionSummary>
      <AccordionDetails>
        <S.StyledSliderBox>
          <SMFSlider
            defaultValue={1}
            minValue={1}
            maxValue={60}
            value={scheduleAutoTrigger}
            setValue={handleSliderChange}
            fixedTo={0}
            step={1}
            label={MESSAGE_STRINGS.DAYS}
          />
        </S.StyledSliderBox>

        <S.SaveButtonContainer>
          <Button
            onClick={handleSaveClick}
            disabled={!isFirstSave}
            data-testid={TEST_IDS.SCHEDULE_AUTO_TRIGGER_SAVE_BUTTON}
          >
            {MESSAGE_STRINGS.SAVE}
          </Button>
        </S.SaveButtonContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default ScheduleAutoTrigger;

ScheduleAutoTrigger.propTypes = {
  enableAccordian: PropTypes.bool,
  scheduleAutoTrigger: PropTypes.number,
  setScheduleAutoTrigger: PropTypes.func,
  scheduleAutoTriggerstatus: PropTypes.string,
  setScheduleAutoTriggerStatus: PropTypes.func,
};
