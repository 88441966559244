import React, { forwardRef } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import FileUploadIcon from '../../assets/img/fileUploadIcon.svg';
import XlsxFile from '../../assets/img/xlsx.svg';
import DownloadIcon from '../../assets/img/download_file.svg';
import InfoIcon from '../../assets/img/infoIcon.svg';
import XLSXFileIcon from '../../assets/img/fileIconXLSX.svg';
import { getAcceptType, formatBytes } from '../../utils/helpers';
import { MESSAGE_STRINGS } from '../../constants/en-us';
import LoadingIndicator from '../LoadingIndicator';
import {
  CustomizedDropZone,
  UploadTitle,
  StyledTooltip,
  StyleIconButton,
  UploadLoadingContentContainer,
  UploadedFile,
} from './style.js';

const FileComponentContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.blackGrey,
  boxShadow: '0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)',
  borderRadius: '.25rem',
  display: 'flex',
  alignItems: 'center',
  padding: '1.5rem 1.25rem 1.5rem 1.5rem',
  width: 500,
}));

function CustomFileComponent({ file = {} }) {
  const { name = '', size = 0 } = file;
  return (
    <FileComponentContainer>
      <XLSXFileIcon height={52} width={52} />
      <Box
        sx={{
          marginLeft: '2rem',
          width: '75%',
        }}
      >
        <Typography
          variant="subtitle2"
          data-testid="filename_ds"
          sx={{
            textTransform: 'none',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {name}
        </Typography>
        <Typography
          data-testid="filesize_ds"
          variant="subtitle2"
          sx={{
            color: (theme) => theme.palette.text.lightWhiteGrey,
          }}
        >
          {formatBytes(size)}
        </Typography>
      </Box>
    </FileComponentContainer>
  );
}
CustomFileComponent.propTypes = {
  file: PropTypes.shape({ name: PropTypes.string, size: PropTypes.number }),
};

function getDropzoneProps(isPropEnabled, getRootProps) {
  return isPropEnabled ? getRootProps({ className: 'dropzone' }) : {};
}

const UploadFile = forwardRef((props, ref) => {
  const {
    title = 'upload title',
    entityHierarchy = '',
    uploadType = '',
    uploadTitle = 'Type: {XLSX}',
    isFileUploadError = false,
    fileType = 'xlsx',
    uploadTooltipContent = '',
    isLoading = false,
    downloadFile = {},
    uploadedFile = {},
    setFilesUploaded = () => null,
  } = props;
  function onDropAccepted(acceptedFilesData) {
    setFilesUploaded((prevState) => ({
      ...prevState,
      [uploadType]: acceptedFilesData[0],
    }));
  }
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted,
    accept: getAcceptType(fileType),
  });

  const isUploadFromBoxEnabled =
    (entityHierarchy && !isLoading) || isFileUploadError;
  return (
    <Box display="flex" flexDirection="column" height="100%" ref={ref} mb={2}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        padding={2}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{title}</Typography>
          <StyledTooltip title={uploadTooltipContent} placement="right-end">
            <StyleIconButton>
              <InfoIcon height="1rem" width="1rem" />
            </StyleIconButton>
          </StyledTooltip>
        </Box>

        <Box pb={2} pt={2}>
          <Typography variant="subtitle2">Upload File</Typography>
        </Box>

        <CustomizedDropZone
          data-testid="primary-upload-button"
          onClick={(e) => getRootProps().onClick(e)}
          {...getDropzoneProps(isUploadFromBoxEnabled, getRootProps)}
          width="100%"
          minHeight="14.75rem"
          isUploadFromBoxEnabled={isUploadFromBoxEnabled}
        >
          {!isLoading && !uploadedFile && (
            <>
              <input {...getInputProps()} />
              <FileUploadIcon
                width={50}
                height={50}
                data-testid="upload_genci_Icon"
              />
              <UploadTitle variant="subtitle1">{uploadTitle}</UploadTitle>
            </>
          )}
          {isLoading && (
            <Box display="flex" alignItems="center">
              <LoadingIndicator size={32} />
              <UploadLoadingContentContainer>
                {MESSAGE_STRINGS['UploadComponent.loading']}
              </UploadLoadingContentContainer>
            </Box>
          )}
          {uploadedFile && !isLoading && (
            <CustomFileComponent
              file={uploadedFile}
              error={isFileUploadError}
            />
          )}
        </CustomizedDropZone>
        {downloadFile && (
          <UploadedFile>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  marginLeft: '2rem',
                  width: '75%',
                }}
              >
                <XlsxFile height={45} width={45} />
                <Box
                  sx={{
                    marginLeft: '1rem',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textTransform: 'none',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {downloadFile?.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: (theme) => theme.palette.text.lightWhiteGrey,
                    }}
                  >
                    {formatBytes(downloadFile.size)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <DownloadIcon height={35} width={35} />
              </Box>
            </Box>
          </UploadedFile>
        )}
      </Box>
    </Box>
  );
});

UploadFile.propTypes = {
  entityHierarchy: PropTypes.string,
  uploadTitle: PropTypes.string,
  isFileUploadError: PropTypes.bool,
  fileType: PropTypes.string,
  getUploadUrl: PropTypes.func,
  isLoading: PropTypes.bool,
  infoContent: PropTypes.string,
  isPreviewComponentEnabled: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  uploadType: PropTypes.string,
  uploadTooltipContent: PropTypes.string,
  setFilesUploaded: PropTypes.func,
  downloadFile: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
    url: PropTypes.string,
  }),
  uploadedFile: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number,
  }),
};

export default UploadFile;
