// Button Constants
export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};
export const BUTTON_SIZE = {
  STANDARD: 'standard',
  SMALL: 'small',
};

// Toast Constants
export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

export const COMPONENT_ID = {
  STEPPER: 'step-',
  LOADER_TEST_ID: 'loading-indicator',
};

export const BLOCK_LEVEL_INDICATOR_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  INFO: 'info',
};
export const INDICATOR_SIZE = { STANDARD: 'standard', SMALL: 'small' };

export const TOAST_DISPLAY_TIME = 3500;

export const FILE_STATUS = {
  ERROR: 'Failed',
  IN_PROGRESS: 'In_Progress',
  COMPLETED: 'Success',
};

export const HIERARCHY_LEVEL = {
  PLANT: 'Plant',
  AREA: 'Area',
  ZONE: 'Zone',
  LINE: 'Line',
  CELL: 'Cell',
  ASSET: 'Asset',
};

export const FILE_CONTENT_TYPE_PDF = 'application/pdf';

export const TEST_IDS = {
  COMBO_BOX: 'combo_box',
  DATA_SOURCE_TOOLTIP: 'genci-data-source-tooltip',
  DATA_SOURCE_TOOLTIP_ICON: 'genci-data-source-toolip-icon',
  EDIT_BUTTON: 'edit-button',
  SYNC_BUTTON: 'sync-button',
  DISABLED_SYNC_BUTTON: 'disabled-sync-button',
  CANCEL_SYNC_BUTTON: 'cancel-sync-button',
  PLANNING_HORIZON_CONTAINER: 'planning-horizon',
  PLANNING_HORIZON_SAVE_BUTTON: 'planning-horizon-save',
  SCHEDULE_AUTO_TRIGGER_CONTAINER: 'schedule-auto-trigger',
  SCHEDULE_AUTO_TRIGGER_SAVE_BUTTON: 'schedule-auto-trigger-save',
  RUN_CONTAINER: 'run-container',
  RUN_BUTTON: 'run-button',
};

export const DATA_SOURCES = {
  NONE: 'Select',
  DMS: 'Document Management System',
  DOC_UPLOAD: 'Document Upload',
};

export const DROPDOWN_CONSTANTS = { DEFAULT_VALUE: 'SELECT' };

export const MESSAGE_STRINGS = {
  'GeneralDropdown.default.placeholder': 'Select',
};

export const QUERY_CONSTANTS = {
  FETCH_GENCI_DATA_SOURCES: 'FETCH_GENCI_DATA_SOURCES',
  TRIGGER_DS_MODEL: 'triggerDSRunModel',
  GET_DMS_FOLDERS: 'getFolders',
  SAVE_DMS_FOLDERS: 'saveFolders',
  GET_PLANT_TIMEZONE: 'getPlantTimezone',
  GET_SINGLEDB_AUTO_PROMPT_CONFIG_STATUS: 'getSingleDbAutoPromptConfigStatus',
  GET_VA_AUTO_PROMPT_CONFIG_STATUS: 'getVirtualAssistantAutoPromptConfigStatus',
};

export const DMS_WS_ACTION = 'GenciDmsRoute';

export const LOADING_TEXT = 'Loading...';

export const DMS_WS = {
  WS_DISCONNECTION_CODE: 1001,
  maxRetries: 5,
  retryInterval: 1000,
};
