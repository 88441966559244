import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Link,
  Tooltip,
  styled,
  tooltipClasses,
  Typography,
  Box,
} from '@mui/material';

export const FlexBox = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const AccordionSummaryContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.625rem',
});

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: theme.palette.text.primary,
}));

export const ConfigRow = styled('div')(({ theme }) => ({
  fontSize: '0.875rem',
  padding: '1.156rem 1.25rem',
  // marginTop: '1rem',
  height: '3.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.customColors.wrapperGrey}`,
  borderBottom: `1px solid ${theme.customColors.wrapperGrey}`,
}));

export const ExternalLinkWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '0.375rem',
  alignItems: 'center',
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.darkAquaBlue,
  cursor: 'pointer',
}));

export const SaveButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '3.5rem',
  width: '100%',
  justifyContent: 'flex-end',
  padding: '0 0.5rem',
}));

export const SyncButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '1.5rem',
  width: '13.62rem',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '1.25rem',
  padding: '0.37rem 0.75rem',
  color: theme.palette.text.primary,
  ':hover': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
  },
}));

export const DisabledSyncButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '1.5rem',
  width: '13.62rem',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '1.25rem',
  padding: '0.37rem 0.75rem',
  color: theme.palette.text.primary,
  opacity: 0.2,
  pointerEvents: 'none',
}));

export const CancelSyncButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '1.5rem',
  width: '4rem',
  border: `1px solid ${theme.palette.border.primary}`,
  borderRadius: '1.25rem',
  padding: '0rem 0.625rem 0rem 0rem',
  color: theme.palette.text.primary,
  ':hover': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
  },
}));

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  '&.MuiCircularProgress-root': {
    height: '2rem !important',
    width: '2rem !important',
    color: theme.palette.background.default,
  },
}));

export const SyncTypography = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '0.75rem',
  lineHeight: '1.02rem',
  width: '11.75rem',
  height: '1rem',
  textTransform: 'none',
}));

export const CancelTypography = styled(Typography)(() => ({
  marginLeft: '0.55rem',
  fontWeight: 600,
  fontSize: '0.75rem',
  lineHeight: '1.02rem',
  width: '11.75rem',
  height: '1rem',
  textTransform: 'none',
}));

export const SyncMsgTypography = styled(Typography)(() => ({
  marginLeft: '0.55rem',
  fontWeight: 700,
  fontSize: '0.875rem',
  lineHeight: '1.02rem',
  width: '11.75rem',
  height: '1rem',
  textTransform: 'none',
  fontStyle: 'italic',
}));
export const SyncTimeTypography = styled(Typography)(({ theme }) => ({
  marginLeft: '1rem',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.02rem',
  width: '4rem',
  height: '1rem',
  textTransform: 'none',
  color: theme.palette.text.lightUnitGrey,
}));

export const OuterFlexContainer = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

export const InnerFlexContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const BackdropContainer = styled(Box)(() => ({
  display: 'flex',
  maxHeight: '100%',
  flex: 1,
  flexDirection: 'column',
}));

export const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export const InfoIconWrapper = styled('div')(() => ({
  marginRight: '1rem',
}));

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.customColors.eerieBlack,
    border: `0.06rem solid ${theme.customColors.metalGrey}`,
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
    borderRadius: '0.25rem',
    padding: '.625rem 0.625rem .625rem .625rem',
    fontColor: theme.customColors.lightYellowishGray,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    maxWidth: '20.375rem',
  },
}));

export const SaveCancelWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '15.625rem',
  alignItems: 'center',
  marginLeft: 'auto',
  columnGap: '0.625rem',
  marginRight: '1rem',
}));

export const ButtonTypography = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1rem',
});

export const ModalTitleWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '0.75rem',
  alignItems: 'center',
});

export const ConfirmTypography = styled(Typography)({
  fontWeight: 700,
});

export const ModalContentText = styled(Typography)({
  fontWeight: 400,
  lineHeight: '1.19rem',
  fontSize: '0.875rem',
  marginTop: '1rem',
});

export const ModalConfirmationText = styled(Typography)({
  fontWeight: 700,
  lineHeight: '1.19rem',
  fontSize: '0.875rem',
  marginTop: '1rem',
});

export const StyleIconButton = styled(IconButton)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
}));

export const StyledAccordionDetailBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '1rem',
  alignItems: 'center',
});

export const LoadingIconBox = styled(Box)({
  marginLeft: '1rem',
  marginRight: '0.5rem',
});

export const EditIconBox = styled(Box)({
  margin: '0rem 1rem',
  cursor: 'pointer',
});
