import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import { OutlinedInput } from '@mui/material';

export const StyledBox = styled('div')({
  width: '100%',
  paddingTop: '0.4em',
  display: 'flex',
  gap: '0.5em',
  alignItems: 'flex-end',
});

export const StyledWrapper = styled('div')({
  width: '100%',
});

export const LimitText = styled('div')({
  fontSize: '0.75em',
});

export const StyledSlider = styled(Slider)(({ theme, ...props }) => ({
  color: theme.palette.primary.main,
  height: 5,
  padding: '0.9375em 0',
  '& .MuiSlider-thumb': {
    height: 18,
    width: 18,
    backgroundColor: theme.palette.common.black,
    border: `0.0625em solid ${theme.palette.common.white}`,
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 400,
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.common.white,
    '&::before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.common.white,
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor:
      props.value !== props.defaultValue
        ? theme.palette.background.saveGreen
        : theme.palette.common.white,
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    boxShadow: `inset 0 0 0.25em -0.125em ${theme.palette.common.black}`,
    backgroundColor: theme.palette.grey[500],
  },
}));

export const StyledInput = styled(OutlinedInput)(({ theme, ...props }) => ({
  fontSize: '0.875em',
  marginBottom: '0.5em',
  marginLeft: '0.5em',
  border:
    props.value !== props.defaultValue
      ? `0.0625em solid #0091CE`
      : `0.0625em solid ${theme.palette.common.white}`,
  input: {
    padding: '0.4em',
  },
  '& .Mui-focused': {
    borderColor: 'inherit',
  },
}));
