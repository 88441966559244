import * as React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import {
  StyledInput,
  StyledSlider,
  StyledBox,
  LimitText,
  StyledWrapper,
} from './styles';
import MESSAGE_STRINGS from '../../constants/en-us';

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

export default function SMFSlider(props) {
  const {
    setValue,
    value,
    maxValue,
    minValue,
    defaultValue,
    fixedTo,
    step,
    label,
  } = props;

  const decimalPlaces =
    ((maxValue - minValue) / 10)?.toString()?.split('.')[1]?.length || 0;

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === '' ? null : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value === null) setValue(0);
    else if (value < minValue) {
      setValue(minValue);
    } else if (value > maxValue) {
      setValue(maxValue);
    } else {
      const roundedValue =
        fixedTo !== undefined
          ? Number(value.toFixed(fixedTo))
          : Number(value.toFixed(decimalPlaces));
      setValue(roundedValue);
    }
  };

  if (defaultValue === null) {
    return null;
  }

  return (
    <StyledWrapper>
      <StyledBox>
        <StyledSlider
          aria-label="slider"
          valueLabelDisplay="on"
          value={value}
          onChange={handleSliderChange}
          min={minValue}
          max={maxValue}
          step={step || (maxValue - minValue) / 10}
          defaultValue={Number(defaultValue)}
        />
        <StyledInput
          value={value}
          size="small"
          onChange={handleInputChange}
          inputProps={{
            step: step || (maxValue - minValue) / 10,
            min: minValue,
            max: maxValue,
            type: 'number',
            'aria-labelledby': 'input-slider',
          }}
          onBlur={handleBlur}
          defaultValue={defaultValue}
        />
      </StyledBox>
      <LimitText>
        <div>
          {MESSAGE_STRINGS.LIMIT} {minValue} - {maxValue} {label}
        </div>
      </LimitText>
    </StyledWrapper>
  );
}

SMFSlider.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  fixedTo: PropTypes.number,
  step: PropTypes.number,
  label: PropTypes.string,
};
