import { dsConfig } from '@smf/ui-util-app';
import axios from 'axios';

/**
 ** API Helper functions goes here
 */
export async function getPreSignedURL(data) {
  const result = dsConfig.getDSPresignedUploadUrl(data);
  return result;
}

export async function uploadFileToS3(presignedUrl, file) {
  console.log(presignedUrl, file);
  return axios({
    url: presignedUrl,
    method: 'put',
    data: file,
    headers: { 'Content-Type': file.type },
  });
}

export async function getDSModelRun(params) {
  return dsConfig.getDSModelRun(params);
}
