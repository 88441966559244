import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';

const CustomizedDropZone = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUploadFromBoxEnabled',
})(({ theme, isUploadFromBoxEnabled }) => ({
  border: `.1rem dashed #0091CE`,
  borderRadius: '0.5rem',
  color: theme.palette.text.primary,
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingY: '1rem',
  '&:hover': {
    cursor: isUploadFromBoxEnabled ? 'pointer' : 'inherit',
  },
}));

const UploadTitle = styled(Typography)(() => ({
  margin: '0.65rem',
}));

const InfoContentContainer = styled(Typography)(({ theme }) => ({
  fontSize: '.875rem',
  minWidth: 475,
  color: theme.palette.text.blockGrey,
  marginTop: '0.5rem',
}));

const UploadLoadingContentContainer = styled(Typography)(() => ({
  marginLeft: '1.25rem',
}));

const InfoIconContainer = styled('section')(() => ({
  margin: '0.56rem',
}));

const InfoContainer = styled(Box)(() => ({
  display: 'flex',
  width: '31.25rem',
}));

const UploadedFile = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  border: `0.06rem solid ${theme.customColors.metalGrey}`,
  marginTop: '0.65rem',
  borderRadius: '0.25rem',
  padding: '0.6rem',
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.customColors.eerieBlack,
    border: `0.06rem solid ${theme.customColors.metalGrey}`,
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
    borderRadius: '0.25rem',
    padding: '.625rem 0.625rem .625rem .625rem',
    fontColor: theme.customColors.lightYellowishGray,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    maxWidth: '20.375rem',
  },
}));

const StyleIconButton = styled(IconButton)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
  marginLeft: '0.5rem',
}));

export {
  CustomizedDropZone,
  UploadTitle,
  StyleIconButton,
  InfoContentContainer,
  UploadLoadingContentContainer,
  InfoIconContainer,
  InfoContainer,
  StyledTooltip,
  UploadedFile,
};
