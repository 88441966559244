export const MESSAGE_STRINGS = {
  PAGE_TITLE_UI_SETTINGS: 'Dynamic Scheduler: Configuration',
  PLANNING_HORIZON: 'Planning Horizon',
  SCHEDULE_AUTO_TRIGGER: 'Schedule Auto Trigger',
  FILE_UPLOAD: 'File Upload',
  ALL_PLANTS: 'All Plants',
  SMART_FACTORY: 'The Smart Factory',
  DYNAMIC_SCHEDULER: 'Dynamic Scheduler',
  SLASH_LINE: '/',
  CONFIGURATION: 'Configuration',
  DAYS: 'days',
  RUN_MODEL: 'Run Model',
  MODEL_RUN_PROCESSING_MESSAGE: 'Model run processing...',
  RUN: 'Run',
  DIALOG_CONFIRM: 'Confirm!',
  DIALOG_CONFIRM_MESSAGE:
    'Running the model will display the output in the Production Order Schedule and on the Dashboard. If model run fails, then the last successful run data will be displayed. Do you want to run the model?',
  GLOBAL_VIEW_NAVIGATION: '/v2',
  PLANT_VIEW_NAVIGATION: '/v2/portfolio_menu',
  DYNAMIC_SCHEDULER_APP_NAVIGATION: '/v2/dynamic_scheduling/dashboard',
  DISPLAY_SETTINGS: 'Display Settings',
  DMS_CONFIG: 'Document Management System (DMS) Configuration',
  FOLDER_PATH: 'Folder path:',
  SEARCH: 'Search',
  DATA_UPLOAD_AND_MAPPING: 'Document Upload',
  EQUIPMENT_HIERARCHY: 'Equipment Hierarchy',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  UPDATE: 'Update',
  EDIT: 'Edit',
  DISCARD: 'Discard',
  CONTINUE: 'Continue editing',
  YES: 'Yes',
  NO: 'No',
  REQUIRED_FIELD: 'Required Field',
  NO_DATA_PRESENT: 'No Data Present',
  TOAST_SUCCESS_MESSAGE: 'Changes have been saved successfully!',
  TOAST_ERROR_MESSAGE: 'Something went wrong. Please try again later!',
  TOAST_DMS_SAVE_MESSAGE: 'Your DMS path has been successfully saved! ',
  TOAST_EMBEDDING_CREATED_MESSAGE:
    'DMS embeddings have been created successfully!',
  SELECT: 'Select',
  MULTIPLE: 'Multiple',
  ALL: 'All',
  HYPHEN: '-',
  PLANNING_HORIZON_TOOLTIP:
    'Select the duration of planning horizon. For example - If 15 is selected as input, model will generate the optimized scheduled for 15 days planning horizon.',
  SCHEDULE_AUTO_TRIGGER_TOOLTIP:
    'App will auto trigger the model run based on the selection. For example - If 3 is selected as input, app will auto trigger the model run every 3rd day.',
  RUN_MODEL_TOOLTIP: `Clicking 'Run' button will initiate the model run. Please upload the input files to proceed.`,
  FILE_UPLOAD_TOOLTIP: `Upload 'Demand data' and 'Line availability info' files to generate update production schedule.`,
  LINE_AVAILABILITY_TOOLTIP: 'Upload updated line availability information',
  DEMAND_DATA_TOOLTIP: 'Upload updated demand data.',
  GENCI_ENABLE_TOOLTIP:
    'Please contact your system admin before enabling this feature.',
  SINGLEDB_AUTO_PROMPT_ENABLE_TOOLTIP:
    'Enable to see automatically generated conversation prompts in GenCI Single DB. \nNote: Enabling will enable only on selected plant.',
  VA_AUTO_PROMPT_ENABLE_TOOLTIP:
    'Enable to see automatically generated conversation prompts in GenCI Virtual Analyst. \nNote: Enabling will enable across entire enterprise.',
  // 'UploadComponent.loading': 'Uploading Data...',
  'GenCIUpload.primaryUploadTitle': 'Upload PDF Maximum size: 100MB',
  'GenCI.uploadInfo.content':
    'Select an asset from equipment hierarchy to upload file.',
  'GenCI.fileUploaded.info': 'File Upload',
  'GenCI.errorInfo.content':
    'Due to an unexpected error, file could not be mapped to the asset. Upload new to replace existing file.',
  'GenCI.progressInfo.content': 'Upload new to replace existing file.',
  FILE_STATUS_TO_DISPLAY: {
    ERROR: 'Error',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
  },
  DATA_SOURCE_CONTAINER_LABEL: 'GenCI Knowledge Management Data Source',
  SYNC: 'Generate DMS embeddings',
  DMS_EMBEDDINGS: 'Creating DMS Embeddings',
  LAST_SYNC: 'Last Sync ',
  DATA_SOURCE_TOOLTIP:
    'Select your preferred data source for GenCI to provide optimized responses based on specific factory documentation',
  DMS_CONFIG_TOOLTIP:
    'Select the folder path to identify the location for GenCI to query documents from the plant’s DMS. Doing so will enable GenCI to provide optimized responses.',
  DMS_CONFIG_EDIT_ICON_TOOLTIP: 'Edit',
  DMS_CONFIG_DISABLED_GENERATE_EMBEDDINGS_TOOLTIP:
    'This action is currently disabled as embeddings are now being generated automatically.',
  CONFIRM: 'Confirm!',
  'DMS.cancelChanges.content': 'You have unsaved changes.',
  'DMS.modal.continue': 'Would you like to continue?',
  'DMS.dataSource.modal.content':
    'Proceeding will remove this integration and any associated data across the platform in connection with the data source.',
  'DMS.saveChanges.content':
    'Editing the folder path will remove any associated data (e.g., embeddings) across the platform related to the DMS.',
  LLM_FINE_TUNING: 'LLM Fine Tuning',
  'LLM.fineTuning.parameter.change':
    'Please confirm you would like to proceed with changes made to the GenCI fine tuning parameters.',
  'LLM.fineTuning.save.change': 'Would you like to save the changes?',
  'LLM.fineTuning.reset':
    'Are you sure you would like to reset all fine tuning parameters?',
  LIMIT: 'Limit',
  PARAMETERS: 'Parameters',
  TEMPERATURE: 'Temperature',
  TOP_P: 'Top P',
  K: 'K',
  DEFAULT_PROMPT_RULES: 'Default Prompt Rules',
  ADDITIONAL_PROMPT_RULES: 'Additional Prompt Rules',
  TEXTAREA_PLACEHOLDER:
    "Enter your prompts here. To maintain the context of prior prompts. To clear data, click 'Reset'.",
  TEMPERATURE_TOOLTIP:
    'Please define a sampling temperature value between 0 and 2. Higher values will make the output more random, while lower values will make the output more deterministic. We recommend altering this or top_p, but not both.',
  TOP_P_TOOLTIP:
    'Please provide a nucleus sampling value for top_p between 0 and 1. This means that only the tokens in the top X% probability mass are considered. We recommend altering this or temperature, but not both.',
  K_TOOLTIP:
    'Please define the number of top results the model should return from 1 to 10. A large k value may result in wasted system resources, while a small k value may result in too small of a dataset being considered.',

  // Upload Component
  'UploadComponent.loading': 'Uploading Files....',
  'UploadComponent.success': 'Your changes have been successfully saved!',
  'UploadComponent.error': 'Failed to upload...',
};

export const ACCORDION_STATUS = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};
export const MODAL_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  CONFIRM: 'confirm',
};
export default MESSAGE_STRINGS;
