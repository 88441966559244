import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const MainContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: '0.625rem 1.3125rem',
}));

const RunButtonContainer = styled(Box)(() => ({
  width: '100%',
  height: '5rem',
  margin: '1rem 0rem',
}));

const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '5.25rem',
  alignItems: 'flex-start',
  justifyContent: 'space-around',
}));

const PageTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '2rem',
  color: theme.palette.text.primary,
}));

const SettingsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const Typo = styled(Typography)(({ theme, isLink }) => ({
  fontWeight: 400,
  color: isLink ? theme.palette.primary.main : '#A7A8AA',
  fontSize: '.75rem',
  lineHeight: '1.02125rem',
  ...(isLink && { cursor: 'pointer' }),
}));

const TypoLine = styled(Typography)(() => ({
  margin: '0rem 0.25rem',
  fontWeight: 400,
  color: '#A7A8AA',
  fontSize: '.75rem',
  lineHeight: '1.02125rem',
}));

const PlantTime = styled(Typography)(() => ({
  fontWeight: 400,
  color: '#A7A8AA',
  fontSize: '1rem',
  lineHeight: '1.5rem',
}));

export {
  MainContainer,
  PageTitle,
  SettingsContainer,
  HeaderContainer,
  Container,
  Typo,
  TypoLine,
  PlantTime,
  RunButtonContainer,
};
