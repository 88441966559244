import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MainContainer,
  PageTitle,
  SettingsContainer,
  HeaderContainer,
  Container,
  Typo,
  TypoLine,
  PlantTime,
  RunButtonContainer,
} from './style.js';
import MESSAGE_STRINGS, { ACCORDION_STATUS } from '../../constants/en-us';
import PlanningHorizon from '../PlanningHorizon/index.jsx';
import ScheduleAutoTrigger from '../ScheduleAutoTrigger/index.jsx';
import RunButton from '../RunButton/index.jsx';
import FileUpload from '../FileUpload/index.jsx';

export default function ConfigContainer() {
  const enableAccordian = true;
  const [planningHorizon, setPlanningHorizon] = useState(null);
  const [planningHorizonstatus, setPlanningHorizonStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED,
  );
  const [scheduleAutoTrigger, setScheduleAutoTrigger] = useState(null);
  const [scheduleAutoTriggerstatus, setScheduleAutoTriggerStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED,
  );
  const [fileUploadstatus, setFileUploadStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED,
  );
  const navigate = useNavigate();

  const navigateToPlantView = () => {
    navigate(MESSAGE_STRINGS.GLOBAL_VIEW_NAVIGATION);
  };

  const navigateToFactoryView = () => {
    navigate(MESSAGE_STRINGS.PLANT_VIEW_NAVIGATION);
  };

  const navigateToDynamicScheduler = () => {
    navigate(MESSAGE_STRINGS.DYNAMIC_SCHEDULER_APP_NAVIGATION);
  };

  return (
    <MainContainer data-testid="app-main-container">
      <HeaderContainer>
        <Container>
          <Typo isLink onClick={navigateToPlantView}>
            {MESSAGE_STRINGS.ALL_PLANTS}
          </Typo>
          <TypoLine>{MESSAGE_STRINGS.SLASH_LINE}</TypoLine>
          <Typo isLink onClick={navigateToFactoryView}>
            {MESSAGE_STRINGS.SMART_FACTORY}
          </Typo>
          <TypoLine>{MESSAGE_STRINGS.SLASH_LINE}</TypoLine>
          <Typo isLink onClick={navigateToDynamicScheduler}>
            {MESSAGE_STRINGS.DYNAMIC_SCHEDULER}
          </Typo>
          <TypoLine>{MESSAGE_STRINGS.SLASH_LINE}</TypoLine>
          <Typo>{MESSAGE_STRINGS.CONFIGURATION}</Typo>
        </Container>
        <PageTitle variant="h3" data-testid="app-page-title">
          {MESSAGE_STRINGS.PAGE_TITLE_UI_SETTINGS}
        </PageTitle>
        <PlantTime>Plant time: 03:50 EST</PlantTime>
      </HeaderContainer>
      <RunButtonContainer>
        <RunButton
          planningHorizonstatus={planningHorizonstatus}
          scheduleAutoTriggerstatus={scheduleAutoTriggerstatus}
          fileUploadstatus={fileUploadstatus}
        />
      </RunButtonContainer>
      <SettingsContainer>
        <PlanningHorizon
          enableAccordian={enableAccordian}
          planningHorizon={planningHorizon}
          setPlanningHorizon={setPlanningHorizon}
          setPlanningHorizonStatus={setPlanningHorizonStatus}
          planningHorizonstatus={planningHorizonstatus}
        />
        <ScheduleAutoTrigger
          enableAccordian={enableAccordian}
          scheduleAutoTrigger={scheduleAutoTrigger}
          setScheduleAutoTrigger={setScheduleAutoTrigger}
          scheduleAutoTriggerstatus={scheduleAutoTriggerstatus}
          setScheduleAutoTriggerStatus={setScheduleAutoTriggerStatus}
        />
        <FileUpload
          enableAccordian={enableAccordian}
          fileUploadstatus={fileUploadstatus}
          setFileUploadStatus={setFileUploadStatus}
        />
      </SettingsContainer>
    </MainContainer>
  );
}
