import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { globalstate$ } from '@smf/ui-util-app';
import Accordion from '../../components/SMFAccordion';
import InfoIcon from '../../assets/img/infoIcon.svg';
import MESSAGE_STRINGS, { ACCORDION_STATUS } from '../../constants/en-us';
import AccordionStatus from '../../components/AccordionStatus';
import * as S from './Style';
import Button from '../../components/Button';
import UploadFile from '../../components/UploadFile';

import { getPreSignedURL, uploadFileToS3 } from '../../utils/apiHelpers';
import { useToastContext } from '../../context/toastContext';
import { TOAST_REDUCER_CONSTANTS } from '../../constants';

const fileList = {
  demandData: null,
  lineAvailability: null,
};
function FileUpload({
  enableAccordian,
  fileUploadstatus,
  setFileUploadStatus,
}) {
  const { AccordionDetails, AccordionSummary } = Accordion;
  const [uploadedFile, setUploadedFile] = useState(fileList);
  const [downloadFile, setDownloadFile] = useState(fileList);
  const [saveDisable, setSaveDisable] = useState(null);
  const errorMessages = '';
  const uploadUserInfo = null;
  const entityId = globalstate$?.value?.plantId;
  const fetchExportDataUrl = () => {};
  const { toastDispatch } = useToastContext();

  const { isLoading: ifFileUploadInProgress, mutate: getUploadUrl } =
    useMutation(
      (file) => {
        const fileNames = Object.keys(file).map((ele) => file[ele].name);
        return getPreSignedURL({
          factoryId: entityId,
          fileNames,
        });
      },
      {
        onSuccess: async (response) => {
          const s3urlMap = {};
          response?.data.forEach((ele) => {
            s3urlMap[ele.fileName] = ele.presignedUrl;
          });
          Object.keys(uploadedFile).forEach(async (fileObj) => {
            const fileName = uploadedFile[fileObj].name;
            await uploadFileToS3(s3urlMap[fileName], uploadedFile[fileObj]);
            setDownloadFile({
              demandData: { name: 'Test1.xlsx', size: 9281, url: '' },
              lineAvailability: { name: 'Test2.xlsx', size: 9281, url: '' },
            });
            setFileUploadStatus(ACCORDION_STATUS.COMPLETED);
            setUploadedFile({ demandData: null, lineAvailability: null });
            toastDispatch({
              type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
              payload: {
                message: MESSAGE_STRINGS['UploadComponent.success'],
              },
            });
          });
        },
        onError: () => {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: MESSAGE_STRINGS['UploadComponent.error'],
            },
          });
        },
      },
    );

  const onSaveClick = () => {
    getUploadUrl(uploadedFile);
  };
  useEffect(() => {
    const uploaded = Object.keys(uploadedFile).every(
      (ele) => uploadedFile[ele] !== null,
    );

    const uploadedStatus = Object.keys(uploadedFile).some(
      (ele) => uploadedFile[ele] !== null,
    );
    if (uploadedStatus) {
      setFileUploadStatus(ACCORDION_STATUS.IN_PROGRESS);
    }

    setSaveDisable(!uploaded);
  }, [uploadedFile]);

  return (
    <Accordion defaultExpanded={enableAccordian} disabled={!enableAccordian}>
      <AccordionSummary>
        <S.FlexBox>
          <S.AccordionSummaryContainer>
            <S.TitleTypography>{MESSAGE_STRINGS.FILE_UPLOAD}</S.TitleTypography>
            <S.StyledTooltip
              title={MESSAGE_STRINGS.FILE_UPLOAD_TOOLTIP}
              placement="right-end"
            >
              <S.StyleIconButton>
                <InfoIcon height="1rem" width="1rem" />
              </S.StyleIconButton>
            </S.StyledTooltip>
          </S.AccordionSummaryContainer>
          <AccordionStatus status={fileUploadstatus} />
        </S.FlexBox>
      </AccordionSummary>
      <AccordionDetails>
        <UploadFile
          title="Demand data"
          uploadType="demandData"
          uploadedFile={uploadedFile?.demandData}
          onExport={fetchExportDataUrl}
          downloadFile={downloadFile?.demandData}
          isLoading={ifFileUploadInProgress}
          tabTitle={
            MESSAGE_STRINGS['EquipmentIntelligence.fileUpload.tabtitle']
          }
          popoverContent={
            MESSAGE_STRINGS['EquipmentIntelligence.fileUpload.popover.content']
          }
          exportCsvTitle={
            MESSAGE_STRINGS['EquipmentIntelligence.fileUpload.export']
          }
          uploadTitle={
            MESSAGE_STRINGS['EquipmentIntelligence.primaryUploadTitle']
          }
          errorMessages={errorMessages}
          uploadTooltipContent={MESSAGE_STRINGS.DEMAND_DATA_TOOLTIP}
          reUploadModalData={{
            enabled: true,
            customText:
              MESSAGE_STRINGS['EquipmentIntelligenec.reUploadModal.customText'],
          }}
          setFilesUploaded={setUploadedFile}
          uploadUserInfo={uploadUserInfo}
        />
        <Divider />
        <UploadFile
          title="Line availability information"
          uploadType="lineAvailability"
          downloadFile={downloadFile?.lineAvailability}
          setFilesUploaded={setUploadedFile}
          uploadedFile={uploadedFile?.lineAvailability}
          isLoading={ifFileUploadInProgress}
          onExport={fetchExportDataUrl}
          tabTitle={
            MESSAGE_STRINGS['EquipmentIntelligence.fileUpload.tabtitle']
          }
          uploadTitle={
            MESSAGE_STRINGS['EquipmentIntelligence.primaryUploadTitle']
          }
          errorMessages={errorMessages}
          uploadTooltipContent={MESSAGE_STRINGS.LINE_AVAILABILITY_TOOLTIP}
        />
        <S.SaveButtonContainer>
          <Button
            onClick={onSaveClick}
            disabled={saveDisable}
            data-testid="saveButton"
          >
            {MESSAGE_STRINGS.SAVE}
          </Button>
        </S.SaveButtonContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default FileUpload;

FileUpload.propTypes = {
  enableAccordian: PropTypes.bool,
  fileUploadstatus: PropTypes.string,
  setFileUploadStatus: PropTypes.string,
};
