import {
  IconButton,
  Tooltip,
  styled,
  tooltipClasses,
  Typography,
  Box,
} from '@mui/material';

export const FlexBox = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const AccordionSummaryContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.625rem',
});

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: theme.palette.text.primary,
}));

export const StyledSliderBox = styled('div')(() => ({
  display: 'flex',
  width: '29.125rem',
  justifyContent: 'space-between',
  padding: '1rem',
  marginLeft: '1rem',
}));

export const SaveButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '3.5rem',
  width: '100%',
  justifyContent: 'flex-end',
  padding: '0 0.5rem',
}));

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.customColors.eerieBlack,
    border: `0.06rem solid ${theme.customColors.metalGrey}`,
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
    borderRadius: '0.25rem',
    padding: '.625rem 0.625rem .625rem .625rem',
    fontColor: theme.customColors.lightYellowishGray,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    maxWidth: '20.375rem',
  },
}));

export const StyleIconButton = styled(IconButton)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
}));
