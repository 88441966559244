import {
  Button,
  IconButton,
  Tooltip,
  styled,
  tooltipClasses,
  Typography,
  Box,
} from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const StyledRunContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  height: '4.5rem',
  borderRadius: '0.25rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledRunModel = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '4px',
}));

export const RunModelText = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '1rem',
  color: theme.palette.text.primary,
  lineHeight: '1.5rem',
  margin: '0 1rem',
}));

export const StyledRunButtonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '0 1rem',
});

export const StyledRunMessage = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
  lineHeight: '1.1875rem',
  marginRight: '1rem',
}));

export const StyledModelRunMessage = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '0.8125rem',
  color: theme.customColors.progressBarBlue,
  lineHeight: '1.375rem',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  width: '3.75rem',
  height: '2.25rem',
  borderRadius: '0.25rem',
  backgroundColor: theme.customColors.progressBarBlue,
  '&:hover': {
    backgroundColor: theme.customColors.progressBarBlue,
  },
  '&:disabled': {
    color: theme.palette.background.darkGrey,
    background: theme.palette.background.darkGrey,
    border: `0.0625rem solid ${theme.palette.background.darkGrey}`,
  },
}));

export const StyledButtonText = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '0.875rem',
  color: theme.customColors.black,
  lineHeight: '1.5rem',
  textTransform: 'none',
}));

export const StyledDialogTitle = styled(DialogTitle)(() => ({
  width: '37.5rem',
  height: '4rem',
  padding: '1rem 1.5rem',
  fontSize: '1.25rem',
  fontWeight: '600',
}));

export const StyledDialogContentText = styled(DialogContentText)(() => ({
  fontSize: '16px',
  fontWeight: '400',
  color: '#DEDEDE',
}));

export const StyledCancelButton = styled(Button)(() => ({
  width: '134px',
  height: '36px',
  borderRadius: '4px',
  border: '1px solid #00A3E080',
  backgroundColor: '#000000',
  fontSize: '14px',
  fontWeight: '600',
  color: '#0091CE',
  textTransform: 'none',
}));

export const StyledConfirmButton = styled(Button)(() => ({
  width: '134px',
  height: '36px',
  borderRadius: '4px',
  backgroundColor: '#0091CE',
  fontSize: '14px',
  fontWeight: '600',
  color: '#000000DE',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#0091CE',
  },
}));

export const SaveButtonContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '3.5rem',
  width: '7.5rem',
}));

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: theme.customColors.eerieBlack,
    border: `0.06rem solid ${theme.customColors.metalGrey}`,
    boxShadow: '0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.5)',
    borderRadius: '0.25rem',
    padding: '.625rem 0.625rem .625rem .625rem',
    fontColor: theme.customColors.lightYellowishGray,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    maxWidth: '20.375rem',
  },
}));

export const StyleIconButton = styled(IconButton)(() => ({
  paddingLeft: 0,
  paddingRight: 0,
}));
