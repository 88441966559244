import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { globalstate$ } from '@smf/ui-util-app';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  QUERY_CONSTANTS,
  TEST_IDS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import { getDSModelRun } from '../../utils/apiHelpers';
import * as S from './style';
import InfoIcon from '../../assets/img/infoIcon.svg';
import { useToastContext } from '../../context/toastContext';

import MESSAGE_STRINGS, { ACCORDION_STATUS } from '../../constants/en-us';

const RunButton = ({
  planningHorizonstatus,
  scheduleAutoTriggerstatus,
  fileUploadstatus,
}) => {
  const entityId = globalstate$?.value?.plantId;
  const [isProcessing, setIsProcessing] = useState(false);
  const [open, setOpen] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const { toastDispatch } = useToastContext();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useQuery({
    queryKey: [QUERY_CONSTANTS.TRIGGER_DS_MODEL],
    queryFn: () =>
      getDSModelRun({
        factoryId: entityId,
      }),
    onSuccess: (res) => {
      setShouldFetch(false);
      toastDispatch({
        type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
        payload: {
          message: res.output,
        },
      });
      setIsProcessing(false);
    },
    enabled: shouldFetch,
    refetchOnWindowFocus: false,
  });

  const handleConfirm = () => {
    setIsProcessing(true);
    setOpen(false);
    setShouldFetch(true);
  };

  return (
    <S.StyledRunContainer data-testid={TEST_IDS.RUN_CONTAINER}>
      <S.StyledRunModel>
        <S.RunModelText>{MESSAGE_STRINGS.RUN_MODEL}</S.RunModelText>
        <S.StyledTooltip
          title={MESSAGE_STRINGS.RUN_MODEL_TOOLTIP}
          placement="right-end"
        >
          <S.StyleIconButton>
            <InfoIcon height="1rem" width="1rem" />
          </S.StyleIconButton>
        </S.StyledTooltip>
      </S.StyledRunModel>
      <S.StyledRunButtonContainer>
        <S.StyledRunMessage>
          Last successful run: 08/07/2024, 07:30 EST{' '}
        </S.StyledRunMessage>
        {isProcessing ? (
          <S.StyledModelRunMessage>
            {MESSAGE_STRINGS.MODEL_RUN_PROCESSING_MESSAGE}
          </S.StyledModelRunMessage>
        ) : (
          <S.StyledButton
            onClick={handleClickOpen}
            disabled={
              planningHorizonstatus !== ACCORDION_STATUS.COMPLETED ||
              scheduleAutoTriggerstatus !== ACCORDION_STATUS.COMPLETED ||
              fileUploadstatus !== ACCORDION_STATUS.COMPLETED
            }
            data-testid={TEST_IDS.RUN_BUTTON}
          >
            <S.StyledButtonText>{MESSAGE_STRINGS.RUN}</S.StyledButtonText>
          </S.StyledButton>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '37.5rem',
              height: '17.75rem',
              borderRadius: '0.25rem',
              margin: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <S.StyledDialogTitle>
            {MESSAGE_STRINGS.DIALOG_CONFIRM}
          </S.StyledDialogTitle>
          <DialogContent
            PaperProps={{
              style: {
                width: '37.5rem',
                height: '8.5rem',
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0.5rem 1.5rem',
              },
            }}
          >
            <S.StyledDialogContentText>
              {MESSAGE_STRINGS.DIALOG_CONFIRM_MESSAGE}
            </S.StyledDialogContentText>
          </DialogContent>
          <DialogActions
            PaperProps={{
              style: {
                width: '37.5rem',
                height: '5.25rem',
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '1.5rem 10rem',
              },
            }}
          >
            <S.StyledCancelButton onClick={handleClose}>
              {MESSAGE_STRINGS.CANCEL}
            </S.StyledCancelButton>
            <S.StyledConfirmButton onClick={handleConfirm}>
              {MESSAGE_STRINGS.RUN_MODEL}
            </S.StyledConfirmButton>
          </DialogActions>
        </Dialog>
      </S.StyledRunButtonContainer>
    </S.StyledRunContainer>
  );
};

RunButton.propTypes = {
  scheduleAutoTriggerstatus: PropTypes.string,
  planningHorizonstatus: PropTypes.string,
  fileUploadstatus: PropTypes.string,
};

export default RunButton;
