import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '../../components/SMFAccordion';
import InfoIcon from '../../assets/img/infoIcon.svg';
import MESSAGE_STRINGS, { ACCORDION_STATUS } from '../../constants/en-us';
import { TEST_IDS } from '../../constants/index.js';
import AccordionStatus from '../../components/AccordionStatus';
import * as S from './Style';
import Button from '../../components/Button';
import SMFSlider from '../../components/SMFSlider/index.jsx';

function PlanningHorizon({
  enableAccordian,
  planningHorizon,
  setPlanningHorizon,
  setPlanningHorizonStatus,
  planningHorizonstatus,
}) {
  const { AccordionDetails, AccordionSummary } = Accordion;
  const [isFirstSave, setIsFirstSave] = useState(true);

  const handleSaveClick = () => {
    setIsFirstSave(false);
    setPlanningHorizonStatus(ACCORDION_STATUS.COMPLETED);
  };

  const handleSliderChange = (newValue) => {
    setPlanningHorizon(newValue);
    setPlanningHorizonStatus(ACCORDION_STATUS.IN_PROGRESS);
    if (!isFirstSave) {
      setIsFirstSave(true);
    }
  };

  return (
    <Accordion
      defaultExpanded={enableAccordian}
      disabled={!enableAccordian}
      data-testid={TEST_IDS.PLANNING_HORIZON_CONTAINER}
    >
      <AccordionSummary>
        <S.FlexBox>
          <S.AccordionSummaryContainer>
            <S.TitleTypography>
              {MESSAGE_STRINGS.PLANNING_HORIZON}
            </S.TitleTypography>
            <S.StyledTooltip
              title={MESSAGE_STRINGS.PLANNING_HORIZON_TOOLTIP}
              placement="right-end"
            >
              <S.StyleIconButton>
                <InfoIcon height="1rem" width="1rem" />
              </S.StyleIconButton>
            </S.StyledTooltip>
          </S.AccordionSummaryContainer>
          <AccordionStatus status={planningHorizonstatus} />
        </S.FlexBox>
      </AccordionSummary>
      <AccordionDetails>
        <S.StyledSliderBox>
          <SMFSlider
            defaultValue={1}
            minValue={1}
            maxValue={60}
            value={planningHorizon}
            setValue={handleSliderChange}
            fixedTo={0}
            step={1}
            label={MESSAGE_STRINGS.DAYS}
          />
        </S.StyledSliderBox>
        <S.SaveButtonContainer>
          <Button
            onClick={handleSaveClick}
            disabled={!isFirstSave}
            data-testid={TEST_IDS.PLANNING_HORIZON_SAVE_BUTTON}
          >
            {MESSAGE_STRINGS.SAVE}
          </Button>
        </S.SaveButtonContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default PlanningHorizon;

PlanningHorizon.propTypes = {
  enableAccordian: PropTypes.bool,
  planningHorizon: PropTypes.number,
  setPlanningHorizon: PropTypes.func,
  planningHorizonstatus: PropTypes.string,
  setPlanningHorizonStatus: PropTypes.func,
};
