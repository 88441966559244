import { StrictMode } from 'react';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { globalstate$, splitRootFactory } from '@smf/ui-util-app';
import { SplitClient, SplitFactory } from '@splitsoftware/splitio-react';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';
import ConfigContainer from './containers/ConfigContainer';
import { ToastProvider } from './context/toastContext';

export default function Root() {
  const queryClient = new QueryClient();

  return (
    <StrictMode>
      <SplitFactory factory={splitRootFactory}>
        <SplitClient
          splitKey={globalstate$._value?.userData?.email?.toLowerCase()}
        >
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <ToastProvider>
                  <ConfigContainer />
                </ToastProvider>
              </ThemeProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </SplitClient>
      </SplitFactory>
    </StrictMode>
  );
}
